/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils 
   ------------------------
*/

.text-uppercase {
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.text-sm {
	font-size: $font-size-sm;
}

.text-base {
	font-size: $font-size-base;
}

.text-lg {
	font-size: $font-size-lg;
}

.text-shadow {
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
	text-shadow: 2px 2px 2px rgba($primary, 0.1);
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-inherit {
	color: inherit;
}

/*
    Rounded corners - lg, sm 
   ------------------------
*/

.rounded-lg {
	border-radius: $border-radius-lg;
}

.rounded-sm {
	border-radius: $border-radius-sm;
}

.rounded-xl {
	border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.border#{$infix} {
			border: $border-width solid $border-color !important;
		}
	}
}

/*
    Opacity helpers - .opacity-[1-9]   
    ------------------------
*/

$opacity: 1 2 3 4 5 6 7 8 9 10;

@each $current-opacity in $opacity {
	.opacity-#{$current-opacity} {
		opacity: #{$current-opacity/10};
	}
}

/*
    Z-index helpers - .z-index-[10-50]   
    ------------------------
*/

$z-index: 10 20 30 40 50;

@each $current-z-index in $z-index {
	.z-index-#{$current-z-index} {
		z-index: #{$current-z-index};
	}
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/

.letter-spacing-0 {
	letter-spacing: 0 !important;
}

$spacing: 0.1em 0.2em 0.3em 0.4em 0.5em;

@each $current-spacing in $spacing {
	$i: index($spacing, $current-spacing);

	.letter-spacing-#{$i} {
		letter-spacing: #{$current-spacing};
	}
}

/*
     Colours and backgrounds
    ------------------------
*/

@each $color, $value in $theme-colors {
	.text-hover-#{$color} {
		transition: $transition-base;

		&:focus,
		&:hover {
			color: $value !important;
		}
	}

	a.text-hover-#{$color} {
		transition: $transition-base;

		&:focus,
		&:hover {
			color: $value !important;
		}
	}

	.bg-#{$color}-light {
		background-color: tint-color($value, 85%);
	}

	.border-#{$color} {
		border-color: $value !important;
	}
}

@each $color, $value in $grays {
	.bg-gray-#{$color} {
		background: $value !important;
	}

	.text-gray-#{$color} {
		color: $value;
	}
}

@each $color, $value in $theme-colors {
	.badge-#{$color}-light {
		color: $value;
		background-color: theme-color-level($color, -11);
	}
}

/*
     Transparent overlays
    ------------------------
*/

.overlay-content {
	position: relative;
	z-index: 20;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	$next: breakpoint-next($breakpoint, $grid-breakpoints);
	$infix: breakpoint-infix($next, $grid-breakpoints);

	.light-overlay#{$infix},
	.overlay-hover-light#{$infix} {
		@include media-breakpoint-up($next) {
			position: relative;

			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: ' ';
				background: #fff;
			}
		}
	}

	.overlay-hover-light#{$infix} {
		@include media-breakpoint-up($next) {
			&::after {
				transition: $transition-fade;
				opacity: 0;
			}
		}
	}

	.light-overlay#{$infix},
	.overlay-hover-light#{$infix}:hover {
		@include media-breakpoint-up($next) {
			&::after {
				opacity: 0.4;
			}
		}
	}

	.light-overlay#{$infix}-0,
	.overlay-hover-light#{$infix}-0:hover {
		@include media-breakpoint-up($next) {
			&::after {
				display: none;
			}
		}
	}

	.dark-overlay#{$infix} {
		position: relative;

		@include media-breakpoint-up($next) {
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: ' ';
				opacity: 0.4;
				background: #000;
			}
		}
	}

	.dark-overlay#{$infix}-0 {
		@include media-breakpoint-up($next) {
			&::after {
				display: none;
			}
		}
	}
}

/*
     Other
    ------------------------
*/

.overflow-visible {
	overflow: visible !important;
}

.shadow-0 {
	box-shadow: none !important;
}

.overflow-visible {
	overflow: visible !important;
}

.shadow-0 {
	box-shadow: none !important;
}

.line-height-sm {
	line-height: 1 !important;
}

.text-gray {
	color: #aaa !important;
}

.index-forward {
	position: relative;
	z-index: 99;
}

.with-pattern {
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 40vw;
		padding-bottom: 15%;
		position: absolute;
		top: 0;
		right: 0;
		background: url(../img/pattern.svg) repeat;
		background-size: 20rem;
		background-position: top left;
		opacity: 0.07;
	}
}

.with-pattern-full {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background: url(../img/pattern.svg) repeat;
		background-size: 20rem;
		background-position: top left;
		opacity: 0.04;
	}
}

.map-bg {
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background: url(../img/map.svg) no-repeat;
		background-size: auto 100%;
		background-position: center;
		opacity: 0.03;

		@include media-breakpoint-down(md) {
			background-size: 100%;
		}
	}
}

.video-block {
	position: relative;
	.video-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.video-btn {
	transition: all 0.3s;
	position: relative;
	z-index: 99;

	&-light {
		.video-btn-icon {
			background: $light;
		}
	}

	&:hover,
	&:focus {
		.video-btn-icon {
			background: $primary;
			color: #fff;
		}
	}

	i {
		transform: translateX(2px);
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: $primary;
	}

	&-icon {
		display: block;
		width: 50px;
		height: 50px;
		border: 1px solid $primary;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		font-size: 0.85rem;
		margin: 8px;
		background: $white;
		transition: all 0.3s;

		&::before,
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 1px solid rgba($primary, 0.8);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			// transition: all 0.3s;
			animation: ripple-1 2s infinite ease-in-out;
			z-index: -1;
		}

		&::after {
			width: 100%;
			height: 100%;
			border-color: rgba($primary, 0.6);
			animation: ripple-2 2s infinite ease-in-out;
			animation-delay: 0.5s;
		}
	}
}

@keyframes ripple-1 {
	0% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, -50%) scale(1.8);
		opacity: 0;
	}
}

@keyframes ripple-2 {
	0% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, -50%) scale(2);
		opacity: 0;
	}
}

.img-gradient {
	position: relative;
	z-index: 99;

	&::before {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: 30%;
		position: absolute;
		bottom: -2rem;
		left: -2rem;
		background: linear-gradient(45deg, $primary, darken($primary, 10%));
		border-radius: $border-radius;
		z-index: -1;
		opacity: 0.5;
	}

	&-left,
	&-right {
		&::before {
			width: 70%;
			height: 70%;
			padding-bottom: 0;
		}
	}

	&-right {
		&::before {
			left: auto;
			right: -2rem;
		}
	}
}

.partner {
	opacity: 0.5;
}

.icon-block {
	width: 50px;
	height: 50px;
	border-radius: $border-radius;
	background: rgba($primary, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	color: $primary;
	font-size: 0.9rem;
    flex-shrink: 0;

	&-lg {
		width: 80px;
		height: 80px;
	}
}

.numeric-block {
	position: relative;

	&-count {
		position: absolute;
		color: $primary;
		top: -1rem;
		left: 0;
		font-size: 6rem;
		font-weight: $font-weight-bold;
		opacity: 0.2;
		z-index: -1;
		line-height: 1;
	}
}

.table-price {
	font-size: 3rem;
	position: relative;
	display: inline-block;
	line-height: 1;
	@include media-breakpoint-up(lg) {
		font-size: 4rem !important;
	}

	sup {
		position: absolute;
		top: 1rem;
		left: -1rem;
		font-size: 1.5rem;
	}
}

.reset-anchor {
	color: inherit;
	transition: all 0.3s;
	&:hover,
	&:focus {
		color: $primary;
		text-decoration: none;
	}
}

.pricing-featured {
	position: relative;
	z-index: 99;

	&::before,
	&::after {
		content: '';
		width: 100%;
		height: calc(100% + 4rem);
		display: block;
		position: absolute;
		top: -2rem;
		left: 0;
		border-radius: $border-radius;

		@include media-breakpoint-down(md) {
			height: 100%;
			top: 0;
		}
	}

	&::before {
		background: $primary;
		box-shadow: $box-shadow;
	}

	&::after {
		background: url(../img/pattern.svg) repeat;
		background-size: 20rem;
		background-position: top left;
		opacity: 0.05;
	}
}

.list-check {
	list-style: none;
	padding: 0;

	li {
		display: flex;
		align-items: flex-start;
		&::before {
			content: '\f00c';
			display: block;
			margin-right: 0.5rem;
			text-align: center;
			color: $primary;
			font-family: 'Font Awesome\ 5 Free';
			font-weight: 900;
			text-shadow: 0 1px 1px rgba($primary, 0.3);
		}
	}

	&-white {
		li {
			&::before {
				color: $white;
			}
		}
	}
}

.pulled-up-section {
	margin-top: -4rem;
}

.text-lg {
	font-size: 2.5rem;
	line-height: 1;
	@include media-breakpoint-up(lg) {
		font-size: 3.5rem;
	}
}

.text-shadow {
	text-shadow: 1px 2px 1px rgba($primary, 0.2);
}

.accordion {
	.card {
		margin-bottom: 0.5rem;
		border-radius: $border-radius !important;
		box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.07);
	}
	.card-body {
		background: $primary;
		color: #fff;
	}
}

.form-holder {
	border-top: 2px solid $primary;
	position: relative;

	&::before {
		content: '';
		width: 60px;
		height: 60px;
		border-radius: $border-radius;
		color: #fff;
		font-family: 'Font Awesome\ 5 Free';
		font-weight: 900;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		right: 2rem;
		transform: translateY(-50%);
	}
}

.has-quote {
	position: relative;

	&::before {
		content: '\f10d';
		display: block;
		font-family: 'Font Awesome\ 5 Free';
		font-weight: 900;
		color: #000;
		opacity: 0.05;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 8rem;
		z-index: 2;
	}
}

.card-hover-bg {
	background: none;
	transition: all 0.3s;
	&:hover {
		box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.08);
		background-color: #fff;
		color: #212529;
		[class*='svg-icon-'] {
			color: $primary;
		}
	}
}

.bg-pattern {
	position: relative;
	z-index: 99;

	&::before {
		background: tint-color($primary, 50%);;
		box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.08);
	}
	&::after {
		background: url(../img/pattern.svg) repeat;
		background-size: 20rem;
		background-position: top left;
		opacity: 0.05;
	}

	&::after,
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 0.25rem;
	}

	&.bg-v-wide {
		&::before,
		&::after {
			height: calc(100% + 4rem);
			top: -2rem;
		}
	}
}

.transform-0 {
	transform: none !important;
}

.blockquote-text {
	border: 2px solid $gray-200;
	border-radius: 0.25rem;
	position: relative;
	background: $white;
	&::before,
	&::after {
		content: '';
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 15px solid $gray-200;
		position: absolute;
		display: block;
		left: 2rem;
	}
	&::before {
		bottom: -15px;
	}

	&::after {
		bottom: -10px;
		border-top: 15px solid #fff;
	}
}
