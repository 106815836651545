.modal-video-close-btn {
	background: $primary;
	right: 0;
	top: -3rem;
	width: 3rem;
	height: 3rem;
	padding: 0;
	text-align: center;
	line-height: 3rem;
	&:focus {
		outline: none;
	}
	&::before,
	&::after {
		background: none;
		width: auto;
		height: auto;
		color: $white;
		content: '\f00d';
		font-family: 'Font Awesome\ 5 Free';
		font-weight: 900;
		transform: none;
		margin: 0;
		position: static;
	}

	&::after {
		display: none;
	}
}

.swiper-pagination-bullet {
	background: $primary;
	width: 12px;
	height: 12px;
	border: 4px solid transparent;
	margin: 0 4px;
	opacity: 1;

	&-active,
	&:hover {
		transition: all 0.3s;
		background: none;
		border: 4px solid $primary;
	}
}

.leaflet-popup-content-wrapper {
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	font-family: $font-family-base;
	text-align: center;
	display: block;

	p {
		font-size: 0.85rem;
		font-weight: $font-weight-light;
	}
}

.leaflet-container a.leaflet-popup-close-button {
	width: 3rem;
	height: 3rem;
	padding: 0;
	line-height: 3rem;
	text-align: center;
	margin: 0;
	transition: all 0.3s;
}
