/*
*
* ==========================================
* GENERAL
* ==========================================
*
*/

body {
    overflow-x: hidden;
}

.text-sm {
    font-size: 0.9rem !important;
}

strong {
    font-weight: $font-weight-bold;
}

a {
    text-decoration: none;
}

.form-control {
    &::placeholder {
        font-size: 0.85rem;
    }
}

.btn {
    box-shadow: 0 3px 2px rgba($primary, 0.15);
    &-primary,
    &-primary:hover,
    &-primary:focus {
        color: $white;
    }

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
}

.dropdown-item {
    transition: all 0.3s;
}

.dropdown-toggle {
    &::after {
        content: '\f107';
        vertical-align: middle;
        border: none;
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
    }
}

/*
*
* ==========================================
* HERO COMPONENT
* ==========================================
*
*/
.hero {
    &-home {
        min-height: 30rem;
    }
}

/*
*
* ==========================================
* NAVBAR
* ==========================================
*
*/

.header {
    transition: all 0.3s;
    &.active {
        background: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    &.scrollUp {
        transform: translateY(-110%);
    }

    @include media-breakpoint-down(md) {
        background: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
}

.navbar {
    .dropdown-menu {
        @include media-breakpoint-up(lg) {
            display: block;
        }
        visibility: hidden;
        opacity: 0;
        transform: translateY(0.5rem);
        transition: 0.2s ease all;
    }
    .dropdown-menu.show {
        display: block;
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        transition: 0.2s ease all;
    }
    .dropdown-menu {
        border-top: 2px solid $primary;

        &::before {
            content: '';
            display: block;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid $primary;
            position: absolute;
            top: -0.5rem;
            left: 1rem;
        }
    }
}

.map {
    height: 500px;
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 3rem;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: inset 0 5px 8px rgba(0, 0, 0, 0.05);
        z-index: 999;
    }

    &::after {
        top: auto;
        bottom: 0;
        transform: rotateX(180deg);
    }
}

/* ===============================================================
     SCROLL TOP BUTTON
  =============================================================== */
#scrollTop {
    background: $primary;
    color: #fff;
    cursor: pointer;
    width: 150px;
    height: 40px;
    line-height: 40px;
    position: fixed;
    top: calc(50% - 20px);
    right: -155px;
    z-index: 9998;
    font-size: 0.8em;
    transition: all 0.3s;
    padding-right: 1rem;
    box-shadow: 0 0 5px rgba($primary, 0.3);
    border-radius: $border-radius 0 0 $border-radius;
}

#scrollTop.active {
    right: -105px;
}

#scrollTop:hover {
    right: 0;
}

#scrollTop i {
    margin: 0 20px;
}
